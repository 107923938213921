/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../config.js";
import { getOrigin as r } from "../core/urlUtils.js";
const s = new Set(["elevation3d.arcgis.com", "js.arcgis.com", "jsdev.arcgis.com", "jsqa.arcgis.com", "static.arcgis.com"]);
function c(t) {
  return !!t && t.length > 4 && t.startsWith("AAPK");
}
function i(t) {
  const c = r(t, !0);
  return !!c && c.endsWith(".arcgis.com") && !s.has(c) && !t.endsWith("/sharing/rest/generateToken");
}
function n(r, s) {
  return !(!s && !t.apiKey || !i(r));
}
export { c as isApiKey, n as isApiKeyApplicable, i as supportsApiKey };